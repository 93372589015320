import * as Sentry from '@sentry/sveltekit'
import { PUBLIC_SENTRY_DSN } from '$env/static/public'
import { log } from '$lib/utils'
import { dev } from '$app/environment'

Sentry.init({
	dsn: PUBLIC_SENTRY_DSN,
	enabled: !dev,
	environment: dev ? 'development' : 'production',
	// release: __version__ || '2.0.0',
	release: '2.0.0',
	sampleRate: 0.1,
	tracesSampleRate: 0.1,
	replaysSessionSampleRate: 0.05,
	replaysOnErrorSampleRate: 1.0,
	integrations: [Sentry.replayIntegration()],
})

const myErrorHandler = ({ error }) => {
	log.hooks('hooks.client.ts')
	console.error('An error occurred on the client side:', error)
}

export const handleError = Sentry.handleErrorWithSentry(myErrorHandler)
